<template>
    <v-container
        id="be-instrum"
        tag="section"
    >
        <v-row
            justify="center"
            v-if="packageAloe.paytime >= dateNow"
        >        
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Гели Aloe Vera
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Рефссылка, инструкции
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                    <p class="mb-7">
                      Гели Алоэ - инструмент для продажи главного продукта компании LR
                      </p>
                      <p class="mb-10">
                        Цель - Увеличить заинтересованность клиента в гелях. Увеличить количество продаж. Подобрать свой гель, узнать историю и ценность уникального растения. Возможность использования в клиентских чатах и соцсетях. Возможность заинтересовать бизнесом и продуктом нутрициологов и тренеров.
                      </p>
                        <v-expansion-panels
                            popout
                            focusable
                            class="exp-panel-title"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="green">mdi-numeric-1-box-multiple</v-icon>
                                            Твоя реферальная ссылка
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                                        <router-link
                                            color="success"
                                            :to="{name: 'AloeVera', query: { partner: $store.state.user.profile.lr_number }}"
                                        >
                                            Лендинг Aloe Vera
                                        </router-link>
                                    </p>
                                    <p class="mb-n2">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                                        <strong>Твоя реферальная ссылка на лендинг Aloe Vera</strong>:
                                        <v-text-field
                                            :value="`https://newlvl.net/aloe-vera/?partner=${$store.state.user.profile.lr_number}`"
                                            readonly
                                            id="referLinkAloe"
                                        ></v-text-field>
                                    </p>
                                    <v-btn
                                        color="gray"
                                        @click="copyRefLinkAloe"
                                        class="mb-5"
                                    >
                                        Скопировать ссылку
                                    </v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <span>
                                      <v-icon class="mr-2" color="red">mdi-numeric-2-box-multiple</v-icon>
                                      Инструкция по использованию инструмента
                                  </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <p class="mt-10">
                                    <a href="https://teletype.in/@newlvl21/u1gx5OAufiH" target="_blank">
                                      Как использовать данную страничку и питьевые гели для увеличения твоего заработка
                                    </a>
                                  </p>                   
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <span>
                                      <v-icon class="mr-2" color="blue">mdi-numeric-3-box-multiple</v-icon>
                                      Информация по питьевым гелям от компании LR
                                  </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                      <a href="/files/aloe/Питьевой_Гель_Сивера.pdf" target="_blank">Гель Сивера</a>
                                    </p>   
                                    <p class="mt-10">
                                      <a href="/files/aloe/Питьевой_Гель_Эктив_Фридом.pdf" target="_blank">Гель Фридом</a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="/files/aloe/Питьевой_Гель_Асаи.pdf" target="_blank">Гель Асаи</a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="/files/aloe/Питьевой_Гель_Иммун_Плюс.pdf" target="_blank">Гель Иммун+</a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="/files/aloe/Питьевой_Гель_Мед.pdf" target="_blank">Гель Мёд</a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="/files/aloe/Питьевой_Гель_Персик.pdf" target="_blank">Гель Персик</a>
                                    </p>                                 
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <!-- <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="purple">mdi-numeric-4-box-multiple</v-icon>
                                            Путеводитель по материалам от компании LR
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                      <a href="https://taplink.cc/lr_russia?yqrid=tKA0887DEh9" target="_blank">Путеводитель по материалам от компании LR</a>
                                    </p>                                    
                                </v-expansion-panel-content>
                            </v-expansion-panel> -->
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <span>
                                      <v-icon class="mr-2" color="orange">mdi-numeric-4-box-multiple</v-icon>
                                      Заболевания и терапевтические рекомендации
                                  </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <p class="mt-10">
                                    <a href="https://teletype.in/@newlvl21/1DXaXR1rjOA" target="_blank">
                                      Заболевания и терапевтические рекомендации
                                    </a>
                                  </p>                   
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>

                </base-material-card>
            </v-col>
        </v-row>
        <v-row
            justify="center"
            v-if="!packageAloe.paytime || packageAloe.paytime < dateNow"
        >
            <v-col
                cols="12"
                lg="9"
                xl="7"
            >
                <base-material-card
                    color="red"
                    class="px-md-16 px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Ошибка доступа
                        </div>

                        <div class="subtitle-1 font-weight-light">

                        </div>
                    </template>

                    <v-card-text
                        class="mt-10"
                    >
                        <p>
                            <v-icon
                                color="red"
                                class="mr-2"
                            >
                                mdi-alert-circle-outline
                            </v-icon>
                            У вас нет доступа к просмотру данной страницы.</p>
                        <p>
                            <v-icon
                                class="mr-2"
                                color="blue"
                            >
                                mdi-arrow-right-bold-outline
                            </v-icon>
                            Для получения доступа к инструментам обратитесь к куратору:
                            <router-link
                                color="success"
                                :to="{name: 'PayInstruments'}"
                            >
                                Как получить доступ к инструментам
                            </router-link>
                        </p>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "AloeInstrum",
    data: () => ({
        dateNow: new Date().toISOString().substr(0, 10),
        dialog: true,
        paytimeUser: ''
    }),
    computed: {
        ...mapGetters({
          packageAloe: 'user/getProfileAloe'
        }),
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        }
    },
    created () {
        this.initializeUserPackage()
    },
    methods: {
        ...mapActions({
            loadPackageAloe: 'user/loadProfileAloe',
            loadProfilePackage: 'user/loadProfilePackage',
        }),
        copyRefLinkAloe () {
            let referLinkAloe = document.getElementById('referLinkAloe')

            referLinkAloe.select()

            document.execCommand('copy')
        },
        initializeUserPackage() {
            this.loadProfilePackage()
                .then((response) => {
                    this.loadingPackage = false
                    this.paytimeUser = response.data.paytime
                })
        },
    },
    mounted() {
        this.loadPackageAloe()
        this.loadProfilePackage()
    }
}
</script>

<style lang="sass">
.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.img-secret
  max-width: 300px
  margin-right: 10px

.img-blic
  max-width: 300px
  margin: 10px
  border: 1px solid #ccc
  border-radius: 10px

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0

</style>
